import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CRecruitJumbotron,
  CBreadCrumb,
  LWrap,
  CIntroduction,
  CInputBtnInit,
  CSectTitle,
  CDefinition,
  CBtnList,
} from "../../../components/_index";
import RecruitLayout from "../../../components/_extra/recruit_layout";

// markup
const SubPage = ({ data }: { data: any }) => {
  const detail = data.microcmsRecruitHotelstop;
  const pagetitle = `${detail.hotel.hotelname} | ${detail.title} | ${detail.employment[0]}`;
  return (
    <Layout isKv={false}>
      <SEO
        title={pagetitle}
        description={
          detail.description.replace(/\r?\n/g, "") ||
          "ロイヤルパークホテルズ のキャリア採用募集要項ページです。"
        }
        ogimage={detail.eyecatch?.url}
      />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "採用情報",
              path: "/recruit/",
            },
            {
              label: "募集要項",
              path: "/recruit/parttime/",
            },
          ],
          current: {
            label: pagetitle,
          },
        }}
      />
      <RecruitLayout detail={detail} pagetitle={pagetitle} />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($id: String!) {
    microcmsRecruitHotelstop(id: { eq: $id }) {
      description
      eyecatch {
        url
        width
        height
      }
      hotel {
        id
        hotelname
        hotelimage {
          url
          height
          width
        }
        hotelheadline
        hotelbody
      }
      recruitHotelstopId
      publishedAt
      title
      employment
      location
      entrybtn
      contents {
        fieldId
        heading_text
        wysiwyg
        box
        align
        caption
        label
        table
        youtube
        htmlcode
        type
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            align
            caption
            label
            table
            youtube
            htmlcode
            type
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          align
          caption
          label
          table
          youtube
          htmlcode
          type
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          align
          caption
          label
          table
          youtube
          htmlcode
          type
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`;
